import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import {DataGrid} from '@mui/x-data-grid';
import {StructuredMovieTable} from '../Components.js/StructuredTable';
import { loggedInStates } from './ToolIndex';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Input, Skeleton, TextField } from '@mui/material';
import ApiHandler from '../utilities/ApiHandler';
import { Add, Create, CreateNewFolder } from '@mui/icons-material';

function Tables() {
    const location = useLocation();
    const navigate = useNavigate();

    const {setPageName, tables} = useContext(loggedInStates);
    const [loadingData, setLoadingData] = useState(true);
    const [tableId, setTableId] = useState(false);

    const [originalRows, setOriginalRows] = useState();
    useEffect(()=>{
        if (!tables) return;
        for (let table of tables){
            if (table.id==location.pathname.split("/")[location.pathname.split("/").length-1]){
                loadTableData(table.id, table.type);
                setPageName(`Tabelle: ${table.name}`)
                setTableId(table.id);
                return;
            }
        }
    }, [location, tables]);

    async function loadTableData(tablid, type){
        switch(type){
            case "movie":
                const result = await ApiHandler.get("/getMovieTableSimple?table="+tablid);
                if (result.success){
                    setOriginalRows(result.response.result);
                    setLoadingData(false);
                }
                break;
        }
    }

    const [filteredRows, setFilteredRows] = useState();
      
    const [searchVal, setSearchVal] = useState("");
    
    const [numSearch, setNumSearch] = useState(false);

    useEffect(()=>{
        if (!originalRows) return;
        if (searchVal && !numSearch){
            setFilteredRows([...originalRows].filter(row => row.title.toLowerCase().includes(searchVal.toLowerCase())));
        }else if (searchVal && numSearch){
            setFilteredRows([...originalRows].filter(row => row.intid.toString().includes(searchVal.toLowerCase())));
        }else{
            setFilteredRows(originalRows);
        }
    }, [searchVal, originalRows, numSearch]);

    if (loadingData){
        return ( 
        <div className='flex flex-col justify-center items-center'>
            <CircularProgress color='success'/>
            Lade Tabelle...
        </div>
        )
    }else{
        return (
            <div className=''>
                <div>
                    <Button variant='outlined' startIcon={<Add/>} onClick={()=>{navigate(`/film/${tableId}/neu`)}}>Neuer Eintrag</Button>
                </div>
                <div className='flex justify-center space-x-2'>
                    <TextField value={searchVal} onChange={(e)=>{setSearchVal(e.target.value)}} placeholder='Suche...' className='w-1/2' variant="outlined"/>
                    <FormControlLabel control={<Checkbox checked={numSearch} onClick={(e)=>{setNumSearch(e.target.checked)}}/>} label="Nummersuche"/>
                </div>
                <StructuredMovieTable rows={filteredRows} id={tableId}/>
            </div>
        )
    }
}

export default Tables