import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material'
import React, { Fragment, useContext, useState } from 'react'
import ApiHandler from '../utilities/ApiHandler';
import { loggedInStates } from '../Tool/ToolIndex';

function NewTable({open, handleClose}) {
    const [name, setName] = useState("");
    const [type, setType] = useState("movie");

    const {setTables} = useContext(loggedInStates);

  return (
    <Fragment>
        <Dialog open={open} onClose={handleClose} PaperProps={{
            component: "form",
            onSubmit: async (e) => {
                e.preventDefault();
                const result = await ApiHandler.post("/newTable", {name, type});
                if (result.success){
                    setTables(result.response.result);
                }
                handleClose();
            }
        }}>
            <DialogTitle>Neue Tabelle</DialogTitle>
            <DialogContent className='flex flex-col p-4'>
                <TextField value={name} onChange={(e)=>{setName(e.target.value)}} label={"Name"} autoFocus variant='filled' required/>
                <FormControl>
                    <Select variant='filled' value={type} onChange={(e)=>{setType(e.target.value)}}>
                        <MenuItem value="movie">Filme</MenuItem>
                    </Select>
                    <FormHelperText>Tabellenart</FormHelperText>
                </FormControl>
                <DialogActions>
                    <Button onClick={handleClose}>Schließen</Button>
                    <Button type="submit">Erstellen</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </Fragment>
  )
}

export default NewTable