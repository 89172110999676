import React, { createContext, useContext, useEffect, useState } from 'react'
import { GlobalStates } from '..'
import ApiHandler from '../utilities/ApiHandler';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Layout from './Layout';
import Home from './Home';
import { CircularProgress, Divider } from '@mui/material';
import Tables from './Tables';
import Movie from '../Components.js/Movie';

export const loggedInStates = createContext(null);

function ToolIndex() {
    const {tokendata, setTokendata} = useContext(GlobalStates);
    const [userdata, setUserdata] = useState();
    const [globalLoading, setGlobalLoading] = useState(false);
    const [pageName, setPageName] = useState("Home");
    const [tables, setTables] = useState();

    const loggedInState = {
        userdata, setUserdata,
        pageName, setPageName,
        tables, setTables,
    }

    useEffect(()=>{
        if (!tokendata) return;
        ApiHandler.setToken(tokendata.session_token);
        loadData();
    }, [tokendata]);

    async function loadData(){
        setGlobalLoading(true);
        const tableResult = await ApiHandler.get("/getTables");
        if (tableResult.success){
            setTables(tableResult.response.result);
        }else{
            
        }
        setGlobalLoading(false);
    }
  return (
    <div className='w-screen h-screen p-0 m-0 overflow-x-hidden'>
    <loggedInStates.Provider value={loggedInState}>
        {globalLoading?
            <div className='flex flex-col justify-center items-center w-full h-full space-y-2'>
                <CircularProgress size={100} color='success'/>
                <p className='text-2xl'>Lade Daten...</p>
            </div>
            :
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Layout/>}>
                        <Route index path='/' element={<Home/>}/>
                        <Route path="/tabelle/*" element={<Tables/>}/>
                        <Route path="/film/*" element={<Movie />}/>
                    </Route>
                </Routes>
            </BrowserRouter>

        }
    </loggedInStates.Provider>
            
    </div>
  )
}

export default ToolIndex