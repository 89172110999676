import React, { useContext, useState } from 'react'
import { GlobalStates } from '.';
import { Alert, Button, Checkbox, Divider, FormControl, FormControlLabel, Paper, Stack, TextField } from '@mui/material';
import ApiHandler from './utilities/ApiHandler';

function LoginPage() {
    const {setTokendata, keepLogin, setKeepLogin} = useContext(GlobalStates);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [wrongInput, setWrongInput] = useState(false);

    const login = async(e)=>{
        e.preventDefault();
        const result = await ApiHandler.post("/auth", {nick: username, password: password});
        if (result.success){
            setTokendata(result.response.result)
        }else{
            switch(result.response.errMsg){
                case "username_or_password_wrong":
                    setWrongInput("Nutername oder Passwort falsch!")
                    break;
                default:
                    setWrongInput(result.response.errMsg);
                    break;
            }
        }
    }
  return (
    <div className='flex justify-center items-center w-full h-full'>
        <Paper elevation={5} className='p-3 w-full md:w-1/3 md:m-0 m-16'>
            <div>
                <h2 className='text-[30px] text-center'>Login</h2>
                <Divider/>
                <form action='#' onSubmit={login}>
                    <Stack direction={'column'} spacing={1} className='mt-2'>
                        <TextField required label="Nutzername / Nick" variant='outlined' value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
                        <TextField required label="Passwort" variant='outlined' type='password' value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                        <FormControlLabel control={<Checkbox checked={keepLogin} onClick={(e)=>{setKeepLogin(e.target.checked)}} color='success'/>} label="Eingeloggt bleiben"/>
                        {wrongInput&&<Alert severity='error' onClose={()=>{setWrongInput(false)}}>Anmeldung fehlgeschlagen: {wrongInput}</Alert>}
                        <Button variant='contained' color="success" className='font-black' type='submit'>
                            <span className='text-xl'>
                                Absenden
                            </span>
                        </Button>
                    </Stack>
                </form>
            </div>
        </Paper>
    </div>
  )
}

export default LoginPage