import React, { createContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ApiHandler from './utilities/ApiHandler';
import LoginPage from './LoginPage';
import ToolIndex from './Tool/ToolIndex';
import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const GlobalStates = createContext(null);

const darkTheme = createTheme({
  palette: {
    mode: "dark"
  }
})


export default function App(){
  const [tokendata, setTokendata] = useState();
  const [keepLogin, setKeepLogin] = useState(false);
  const globalState = {
    tokendata, setTokendata,
    keepLogin, setKeepLogin
  }

  useEffect(()=>{
    if(!tokendata || !keepLogin) return;
    localStorage.setItem("tokendata", JSON.stringify(tokendata));
  }, [tokendata]);

  useEffect(()=>{
    const storedData = localStorage.getItem("tokendata");
    if(!storedData) return;
    const parsedData = JSON.parse(storedData);
    if(new Date(parsedData.expiry)<new Date(Date.now())) return localStorage.removeItem("tokendata");
    setTokendata(parsedData);
    setKeepLogin(true);
  }, []);

  useEffect(()=>{
    ApiHandler.setLogoutHandler(()=>{
      setTokendata(false);
      ApiHandler.destroy();
    })
  });

  useEffect(()=>{
    if (tokendata) return;
    localStorage.removeItem("tokendata");
  }, [tokendata]);

  useEffect(()=>{
    console.log(keepLogin);
  }, [keepLogin]);

  return (
    <div className='w-screen h-screen'>
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <GlobalStates.Provider value={globalState}>
          {tokendata?
            <ToolIndex/>
            :
            <LoginPage/>
          }
        </GlobalStates.Provider>
      </ThemeProvider>
    </React.Fragment>
    </div>
  )
}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
