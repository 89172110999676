import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, MenuItem, Rating, Select, Stack, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import ApiHandler from '../utilities/ApiHandler';
import { green } from '@mui/material/colors';
import { loggedInStates } from '../Tool/ToolIndex';

function Movie() {
    const navigate = useNavigate();
    const location = useLocation();
    const [tableId, setTableId] = useState(false);
    const [movieId, setMovieId] = useState(false);
    const [movieData, setMovieData] = useState({
        usrtable: -1,
        intid: -1,
        title: "Titel",
        descr: "",
        medium: "DVD",
        rating: 0,
        watched: 0,
        genre: "",
        img: "",
        lngth: 0,
        notes: "",
        price: 0
    })

    useEffect(()=>{
        setMovieId(location.pathname.split("/")[location.pathname.split("/").length-1]);
        setTableId(location.pathname.split("/")[location.pathname.split("/").length-2]);
    }, [location]);

    useEffect(()=>{
        if (!tableId||!movieId) return;
        if (movieId!="neu"){
          getMovie(tableId, movieId);
        }else{
          setMovieData(oldData=>({...oldData, usrtable: tableId}));
        }
    }, [tableId, movieId]);

    async function getMovie(tableId, movieId){
      const result = await ApiHandler.get(`/getMovie?table=${tableId}&movie=${movieId}`);
      if (result.success){
        setMovieData(result.response.result);
      }
    }



    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    async function uploadMovie(e){
      e.preventDefault();
      setLoading(true);
      const result = await ApiHandler.post("/updateMovie", {movie: JSON.stringify(movieData)});
      if (result.success){
        setMovieData(result.response.result);
        navigate(`/film/${tableId}/${result.response.result.intid}`)
        setTimeout(()=>{
          setSuccess(true);
          setLoading(false);
        }, 500);
      }
    }


    const buttonSx = {
      ...(success && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    useEffect(()=>{
      setSuccess(false);
    }, [movieData]);

  return (
    <div>
        <form action='#' onSubmit={uploadMovie} className=''>
          <Stack spacing={{xs: 1, sm: 2}} direction="row" useFlexGap flexWrap="wrap">
            <Stack spacing={{xs: 1, sm: 4}} direction="row" useFlexGap flexWrap="wrap" sx={{width: "100%"}}>
              <TextField value={movieData.title} onChange={(e)=>{setMovieData(oldData=>({...oldData, title: e.target.value}))}} label="Titel" sx={{minWidth: "50%"}}/>
              <Rating value={movieData.rating} onChange={(e)=>{setMovieData(oldData=>({...oldData, rating: e.target.value}))}} className='m-auto'/>
              <Select value={movieData.medium} onChange={(e)=>{setMovieData(oldData=>({...oldData, medium: e.target.value}))}}>
                <MenuItem value="DVD">DVD</MenuItem>
                <MenuItem value="Blu-Ray">Blu-Ray</MenuItem>
                <MenuItem value="VHS">VHS</MenuItem>
              </Select>
              <TextField value={movieData.lngth} onChange={(e)=>{setMovieData(oldData=>({...oldData, lngth: e.target.value}))}} type='number' label="Länge (min)" sx={{width: 150}}/>
              <FormControl>
                <FormControlLabel control={<Checkbox checked={movieData.watched} onChange={(e)=>{setMovieData(oldData=>({...oldData, watched: e.target.checked}))}} color='success'/>} label="Geschaut" className='m-auto'/>
              </FormControl>
            </Stack>
            <Stack spacing={{xs: 1, sm: 2}} direction="row" useFlexGap sx={{width: "100%"}}>
              <TextField value={movieData.descr} onChange={(e)=>{setMovieData(oldData=>({...oldData, descr: e.target.value}))}} label="Beschreibung" multiline rows={10} sx={{width: "100%"}}/>
              <TextField value={movieData.notes} onChange={(e)=>{setMovieData(oldData=>({...oldData, notes: e.target.value}))}} label="Notizen" multiline rows={10} sx={{width: "100%"}}/>
            </Stack>
            <Box sx={{m: 1, position: "relative"}}>
              <Button variant='contained' sx={buttonSx} disabled={loading} type='submit'>
                Speichern
              </Button>
              {loading && (
                <CircularProgress size={24} sx={{color: green[500], position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}}/>
              )}
            </Box>
          </Stack>
        </form> 
    </div>
  )
}

export default Movie