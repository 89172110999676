import { Alert, AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Snackbar, Toolbar, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { Mail, Inbox, Menu, Logout, TableBar, TableChart, NewReleasesOutlined, Add, AddBox, Home, QuestionMark } from '@mui/icons-material';
import ApiHandler from '../utilities/ApiHandler';
import { GlobalStates } from '..';
import { loggedInStates } from './ToolIndex';
import { Link } from 'react-router-dom';
import NewTable from '../Components.js/NewTable';

const drawerWidth = 240;

function Layout(props) {

    const {setTokendata} = useContext(GlobalStates);
    const {userdata, pageName, tables} = useContext(loggedInStates);

    const {window} = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    }

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    }

    const handleDrawerToggle = () => {
        if (!isClosing) setMobileOpen(!mobileOpen);
    }


    const [drawer, setDrawer] = useState();

    useEffect(()=>{
        console.log("New Table");
        const newDrawer = (
            <div>
            <Toolbar />
            <Divider/>
            <List>
                <ListItem key="home" disablePadding>
                <Link to={`/`} className='w-full'>
                        <ListItemButton>
                            <ListItemIcon>
                                <Home/>
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem key="newTable" disablePadding>
                    <ListItemButton onClick={()=>{setNewTableOpen(true)}}>
                        <ListItemIcon>
                            <AddBox/>
                        </ListItemIcon>
                        <ListItemText primary="Neue Tabelle"/>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                {tables&&tables.length>0?tables.map((obj, index) => (
                <ListItem key={obj.name} disablePadding>
                    <Link to={`/tabelle/${obj.id}`} className='w-full'>
                        <ListItemButton>
                            <ListItemIcon>
                                <TableChart/>
                            </ListItemIcon>
                            <ListItemText primary={obj.name} />
                        </ListItemButton>
                    </Link>
                </ListItem>
                )):
                    <ListItem key="notable" disablePadding>
                        <ListItemButton disabled>
                            <ListItemIcon>
                                <QuestionMark/>
                            </ListItemIcon>
                            <ListItemText primary="Keine Tabellen"/>
                        </ListItemButton>
                    </ListItem>
                }
            </List>
            <Divider />
            <List>
                <ListItem key="logout" disablePadding>
                    <ListItemButton onClick={()=>{ApiHandler.destroy(); setTokendata(false)}}>
                        <ListItemIcon>
                            <Logout/>
                        </ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
        )
        setDrawer(newDrawer);
    }, [userdata, tables]);

    const [newTableOpen, setNewTableOpen] = useState(false);

  return (
    <div className='m-0 p-0 w-full'>
        <NewTable handleClose={()=>{setNewTableOpen(false)}} open={newTableOpen}/>
         <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {pageName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                >
                {drawer}
                </Drawer>
                <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
                >
                {drawer}
                </Drawer>
            </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}
                >
                    <Toolbar />
                    <div className='w-full'>
                        <Outlet/>
                    </div>
                </Box>
            </Box>
    </div>
  )
}

export default Layout

