import { ChangeCircle, Delete, DiscFull, DiscFullSharp, DvrRounded, Edit, FormatIndentIncrease, LockClock, Note, Numbers, PermIdentity, PermIdentitySharp, PlayCircle, PunchClock, Search, Settings, Sort, TimeToLeave, Timeline, Title, TitleRounded, TitleTwoTone, Watch, WatchLater } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Input, Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export const StructuredMovieTable = ({rows, id}) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  return (
    <div className=''>
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' sx={{width: "5%"}}>
                            <Numbers/>
                        </TableCell>
                        <TableCell align='center'>
                            <TitleRounded/> Titel
                        </TableCell>
                        <TableCell align='center' sx={{width: "10%"}}>
                            <PlayCircle/>
                        </TableCell>
                        <TableCell align='center' sx={{width: "20%"}}>
                            <Rating value={3} readOnly/>
                        </TableCell>
                        <TableCell align='center' sx={{width: "5%"}}>
                            <WatchLater/>
                        </TableCell>
                        <TableCell align='center' sx={{width: "10%"}}>
                            <Edit/>
                            (Bearbeitungsfunktion folgt)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?rows.slice(page*rowsPerPage, (page+1)*rowsPerPage).map((row) => 
                        (
                            <TableRow key={row.intid}>
                                <TableCell align='center' sx={{width: "5%"}}>
                                    {row.intid}
                                </TableCell>
                                <TableCell align='center'>
                                    {row.title}
                                </TableCell>
                                <TableCell align='center' sx={{width: "10%"}}>
                                    {row.medium}
                                </TableCell>
                                <TableCell align='center' sx={{width: "20%"}}>
                                    <Rating value={row.rating} readOnly/>
                                </TableCell>
                                <TableCell align='center' sx={{width: "5%"}}>
                                    {row.lngth}m
                                </TableCell>
                                <TableCell align='center' sx={{width: "10%"}}>
                                    <Link to={`/film/${id}/${row.intid}`}>
                                        <Button color='secondary'>
                                            <Edit/>
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )
                    ):
                        <TableRow key="notFound">
                            <TableCell align='center' colSpan={6}>
                                <Alert severity='warning' className='w-fit m-auto'>Keine Einträge unter diesem Filter gefunden!</Alert>
                            </TableCell>
                        </TableRow>
                    }
                    <TableRow key="tFooter" sx={{border: 0}}>
                        <TableCell align='center' colSpan={6}>
                            <TablePagination component="div" count={rows?rows.length:0} page={page} showFirstButton={true} showLastButton={true} onPageChange={(e, newP)=>{setPage(newP)}} rowsPerPage={rowsPerPage} onRowsPerPageChange={(e)=>{setRowsPerPage(e.target.value)}} labelDisplayedRows={({from, to, count}) => {
                                return '' + from + '-' + to + ' von ' + count
                            }} labelRowsPerPage={"Zeilen pro Seite:"} 
                            rowsPerPageOptions={[10, 20, 30]}/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}