
import config from "../configs/config"

class ApiHandler {
    #token="";
    #logoutHandler=()=>{};

    destroy(){
        this.#token=null;
    }

    setToken(token){
        if (this.#token!=token){
            this.#token = token;
        }
    }

    setLogoutHandler(logoutHandler=()=>{}){
        this.#logoutHandler=logoutHandler;
    }

    /**
     * 
     * @param {String} query The query uri "/table"
     * @param {Any} data The data to post 
     * @param {Boolean} autoLogout If the logOutHandler should get called upon status 401 or 403 
     * @param {Object} callback Callback function 
     * @returns 
     */
    async post(query="/", data={}, autoLogout=true){
        let headers={};
        if (this.#token) headers['Token'] = this.#token;
        let formData = new FormData();
        for (let key in data){
            formData.set(key, data[key]);
        }
        try {
            const response = await fetch(config.api+query, {
                method: "POST",
                headers: headers,
                body: formData
            });
            const respData = await response.json();
            if (autoLogout && (response.status===401||response.status===403)){
                this.#logoutHandler();
                return {success: false, response: {
                    status: response.status,
                    errMsg: respData.ERROR['Error-Message'],
                    errHeader: respData.ERROR['Error-Header'],
                    processingTime: respData.ERROR.processingTime
                }}
            }
            if (response.ok && respData.result){
                return {success: true, response: respData}
            }else{
                return {success: false, response: {
                    status: response.status,
                    errMsg: respData.ERROR['Error-Message'],
                    errHeader: respData.ERROR['Error-Header'],
                    processingTime: respData.ERROR.processingTime
                }}
            }
        } catch(e){
            return {success: false, response: {
                status: 500,
                errMsg: "Internal Server Error",
                errHeader: "Internal Server Error",
                processingTime: -1
            }}
        }
    }


    /**
     * 
     * @param {String} query The query uri "/items"
     * @param {Boolean} autoLogout If the logOutHandler should get called upon status 401 or 403 
     * @param {Object} callback Callback function 
     * @returns 
     */
    async get(query="/", autoLogout=true){
        let headers = {};
        if (this.#token) headers['Token']=this.#token;
        try {
            const response = await fetch(config.api+query, {
                method: "GET",
                headers: headers
            });
            const respData = await response.json();
            if (autoLogout && (response.status===401||response.status===403)){
                this.#logoutHandler();
                return {success: false, response: {
                    status: response.status,
                    errMsg: respData.ERROR['Error-Message'],
                    errHeader: respData.ERROR['Error-Header'],
                    processingTime: respData.ERROR.processingTime
                }}
            }
            if (response.ok && respData.result){
                return {success: true, response: respData}
            }else{
                return {success: false, response: {
                    status: response.status,
                    errMsg: respData.ERROR['Error-Message'],
                    errHeader: respData.ERROR['Error-Header'],
                    processingTime: respData.ERROR.processingTime
                }}
            }
        } catch(e){
            return {success: false, response: {
                status: 500,
                errMsg: "Internal Server Error",
                errHeader: "Internal Server Error",
                processingTime: -1
            }}
        }
    }
}

export default new ApiHandler();