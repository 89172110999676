import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { loggedInStates } from './ToolIndex';

function Home() {
    const location = useLocation();
    const {setPageName} = useContext(loggedInStates);
    useEffect(()=>{
        setPageName("Home");
    }, [location]);
  return (
    <div>Home</div>
  )
}

export default Home